// import { useEffect, useState } from "react";
// import classes from "./TeamPlanner.module.css";

function TeamMember(props) {
  // const [pokemonJson, setPokemonJson] = useState({
  //   name: "none",
  //   sprites: {
  //     front_default: "",
  //   },
  // });

  // useEffect(() => {
  //   setPokemonJson(props.pokemonJson);
  // }, []);

  // return (
  //   <div className={classes.pokemon}>
  //     <div className={classes.pokemonAndName}>
  //       <h2 className={classes.pokemon_name}>{pokemonJson.name}</h2>
  //       <img
  //         className={classes.pokemon_img}
  //         alt={pokemonJson.name}
  //         src={pokemonJson.sprites.front_default}
  //       />
  //     </div>
  //   </div>
  // );
  return <div></div>;
}

export default TeamMember;
